import { Injectable, Inject  } from '@angular/core';
import { jsPDF } from 'jspdf';
import { environment } from 'src/environments/environment';
import { ApiService } from './api.service';
import { Window } from '../window';
@Injectable({
    providedIn: 'root'
})
export class ProtocoloService {

    private _ambiente = environment.variaveis[environment.ambiente];

    constructor(
        @Inject(Window) private _window: any,
        private _apiService: ApiService
    ) { }

    printProtocolo = async (processo) => {

        const logo = new Image();
        logo.src = 'assets/images/logo-' + this._ambiente.arquivos + '.png';
        
        if (!processo) {
            throw 'O processo a ser emitido o protocolo não foi informado.';
        }

        const nmOrgao: string = this._ambiente.nmOrgao;
        const protocolo = `${processo.nrProcesso}`;

        const nrTelefone: string = (await this._apiService.getParametro('NR_TELEFONE2')).vlInicial;
        const nmEmail: string = (await this._apiService.getParametro('NM_EMAIL')).vlInicial;

        var doc = new jsPDF('landscape', 'mm', [200, 100]);

        doc.setFont("helvetica", "bold");
        doc.setFontSize(17);
        doc.text(nmOrgao.toUpperCase(), doc.internal.pageSize.width / 2, 12, { align: 'center' });
        doc.text('PROTOCOLO DE SOLICITAÇÃO'.toUpperCase(), doc.internal.pageSize.width / 2, 22, { align: 'center' });

        doc.setFont("helvetica", "normal");
        doc.setFontSize(15);
        doc.text('O protocolo gerado para sua solicitação é:', doc.internal.pageSize.width / 2, 35, { align: 'center' });

        doc.setFont("helvetica", "bold");
        doc.setFontSize(20);
        doc.text(protocolo, doc.internal.pageSize.width / 2, 50, { align: 'center' });
        
        doc.setFontSize(11);
        const telefone = nrTelefone !== 'null' && nrTelefone !== '' ? `ou ${nrTelefone}` : '';
        doc.text(`O prazo para o lançamento do processo é de 48h. \nPara verificação do andamento do seu processo, ligue:\n 156 ${telefone}`, doc.internal.pageSize.width / 2, 65, { align: 'center' });

        doc.setDrawColor(0, 0, 0, 0);
        doc.line(10, 75, 10, 75);

        doc.setFont("helvetica", "normal");
        doc.setFontSize(11);
        doc.text(`${nmEmail}`, 5, 95, { align: 'left' });
        doc.text(`Gerado via ${this._window.location.hostname} em ${new Date().toLocaleString('pt-BR')}`, doc.internal.pageSize.width - 5, 95, { align: 'right' });

        doc.save(`protocolo-${protocolo}.pdf`);
    }

    private resizeImage(srcWidth, srcHeight, maxWidth, maxHeight) {

        var ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
    
        return { width: srcWidth*ratio, height: srcHeight*ratio };
    }
}
